.Register-nu{
    display: flex;
    align-items: center;
    justify-content: center;   
}
.Register-nu .reg-area-nu {
display: flex;

align-items: center;
justify-content: center;   
}
.reg-img-nu img{
    height: 16rem;

}
.reg-text-ath-nu{

    width: 30%;
    text-align: justify;
}
.reg-text-ath-nu p,.reg-text-ath-nu h2{
    margin-left: 10%;
}
@media (max-width:1000px) {
    .Register-nu .reg-area-nu {
      align-items: flex-start;
        flex-direction: column;      
        gap: 1rem;
        }
        .reg-text-ath-nu{

            width: 95%;
            text-align: justify;
        }
        .reg-text-ath-nu p,.reg-text-ath-nu h2{
            margin-left: 2%;
            width: 95%;
        }
}
@media (max-width:500px) {
    .reg-img-nu img{
        height: 13rem;
    margin-left: 3%;
    }
}
.Believers{
  padding:4rem 0 ;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: center;
    justify-content: center;
}
.Believers h2{
    font-size: 2.5rem;
   
}
/* .Believers .images img{
  height: 230px;
} */
.Believers .images{
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  scroll-behavior: smooth;
  width: 1090px;
  overflow-x: hidden;
}
.Believers .arrows{

  display: flex;
  gap: 3rem;
}

.Believers .arrows img{
  height: 40px;
}

@media (max-width:800px) {

.Believers .images{
  width: 485px;
}
}
@media (max-width:530px) {
  .Believers .images{
    width: 230px;
  }
  .Believers h2{
    font-size: 2rem;
  
}

}

.Navbar{
    position: relative;
    background-color: #FBF8F0;
}
.nav-area{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.2rem 2rem;
}
.Navbar img{
    height: 4.5rem;
}
.Navbar svg{
    font-size: 2rem;
    cursor: pointer;
}
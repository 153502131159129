.myBlogs-bg{
    background-color: #FBF8F0; 
}

.myBlogs-title{
    color: #000000;
    font-size: 40px;
    font-family: "Oswald", sans-serif;
    font-weight: 700;
}

.Flagship{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 4rem;
    gap: 5%;

}
.flag-txt {
   display: flex;
   flex-direction: column;
   gap: 0.5rem;
   align-items: flex-start;
   justify-content: center;
 
margin-bottom: 3rem;

}
.flag-txt p{
    font-size: 1.6rem;
    word-spacing: 1px;
}
.flag-txt h2{
    font-size: 2.5rem;
}
.flag-txt button{
    height: 50px;
    width: 200px;
    border: 1px solid #ED5009;
    background-color: #4E62D6;
    color: white;
    border-radius: 2px;
    box-shadow: 10px 10px 0px rgba(0, 0, 0, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    cursor: pointer;
}
.flag-img img{
    height: 24rem;
}
.flag-img .rishi-small{
    display: none;
}
@media (max-width:1300px) {
    .Flagship{
       margin: 0 2rem;
    
    }
}
@media (max-width:1000px) {
    .flag-img img{
        height: 20rem;
    }
}
@media (max-width:600px) {
    .Flagship{
        margin: 0 0rem;
     
     }
    .flag-img .rishi-small{
        display: block;
    }
    .flag-img .rishi-big{
        display: none;
    }
    .Flagship{
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
    }
    .flag-txt p,  .flag-txt h2, .flag-txt button  {
margin-right: 6rem;
    }
    .flag-img img{
        height: 12rem;
        margin-right: 0.1rem;
    }
    .flag-txt {
        margin-bottom: 0rem;
         margin-left: 1rem;
     
     }
     .flag-txt h2{
        font-size: 2rem;
     }
     .flag-txt p{
        font-size: 1.2rem;
     }
     .flag-txt button{
        height: 45px;
        width: 170px;
       
    }
}
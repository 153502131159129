.Feed{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    margin: 4rem 0;
    text-align: center;
}
.Feed h2{

    font-weight: 500;
    font-size: 1.3rem;
}
.insta-feed .ip{
    height: 250px;
    width: 300px;
    border-radius: 1rem;
    cursor: pointer;
    overflow: hidden; 
    position: relative; 
}
.insta-feed .feed-post{
    height: 100%;
    width: 100%;
    object-fit: cover; 
    transition: transform 0.3s ease;
}
.insta-feed .feed-post:hover{
    transform: scale(1.1);
}
.Feed .instagram{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 100%;
    padding: 1rem 0;
}
.Feed .instagram h2{
    margin-bottom: 2rem;
}
.instagram .insta-feed{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    flex-wrap: wrap;
    width: 70%;
}
.insta-feed .ig{
   border-radius: 1rem;
}
.instagram .more-posts{
    font-size: 1rem;
    cursor: pointer;
    transition: 0.3s ease-in;
}
.instagram .more-posts:hover{
    color:#ED5009 ;
}
.instagram .see-more{
    display: flex;
   justify-content: flex-end;
    width: 52%;
}

.Feed .youtube{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 3rem;
 
}
.Feed .youtube .video{
    height: 165px;
    width: 350px;
    border-radius: 15px;
}
.video-area{
    display: flex;
    align-items: center;
    justify-content: center;  
    gap: 3rem;
    flex-wrap: wrap;
}

.Spotify {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 3rem;
    
}
.Spotify .podcasts{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3rem;

  
}
@media (max-width:1500px) {
    .instagram .insta-feed{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3rem;
        flex-wrap: wrap;
        width: 80%;
    }
  

  
}
/* @media (max-width:1670px) {
    *{
        background-color: #ED5009;
    }
} */
@media (max-width:620px) {
    .instagram .see-more{   
        width: 80%;
justify-content: center;    }
    .Feed{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4rem;
        margin: 2rem 0;
    }
    .Feed .youtube .video{
        height: 150px;
        width: 300px;
       
    }
    .spotify-pod{
        width: 95%;
    }
}
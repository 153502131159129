.Worked{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: 2rem 0;
}
.Worked img{
    height: 100px;
}
@media (max-width:1000px) {
    .Worked img{
        height: 60px;
    }
}
@media (max-width:550px) {
    .Worked img{
        height: 35px;
    }
}
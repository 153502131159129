.social-bg{
    background-color: #FBF8F0; 
}

.hero-3 {
    display: grid;
    width: 100%;
    place-items: center;
    background-size: cover;
    background-position: center;
}
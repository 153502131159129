.bg-contact{
    background-color: #FBF8F0;  
  }

  .header-img{
    filter: brightness(70%);
  }

  .feature-title{
    color: #000000;
    font-size: 40px;
    font-family: "Oswald", sans-serif;
    font-weight: 700;
}
  
  .container-1 {
    position: relative;
    text-align: center;
    color: white;
    margin: 0 auto;
  }
  
  /* .centered-1 {
    position: absolute;
    top: 39%;
    left: 33%;
    transform: translate(-50%, -50%);
  }
   */
  .contact-title {
    color: #000000;
    font-size: 25px;
    font-family: "Oswald", sans-serif;
    font-weight: 700;
  }
  
  .top-left {
    position: absolute;
    top: 8px;
    left: 16px;
  }
  
.Movement{
    display: flex;
    align-items: center;   
    justify-content: center;
    margin-bottom: 2rem;
}
.Movement .mov-controler{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8%;
    margin: 0 2rem ;

}
.Movement .m-text{

    width: 35%;
    text-align: justify;
    margin-left: 8rem;


}
.Movement .m-text p{
    width: 80%;
}
.Movement .m-img img{
    height: 18rem;
    object-fit: contain;
}
@media (max-width:1000px) {
    .Movement .mov-controler{
        margin: 0rem 0rem ;
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 2rem;
  
    }
    .Movement img{
     margin-left: 20%;
    }
    .Movement .m-text{
        margin-left: 1rem;
        width: 60%;
        text-align: start;
    }
    .Movement .m-text p{
        width: 100%;
    }
}
@media (max-width:550px) {

    .Movement .m-img  img{
     margin-left: 0%;
     height: 13rem;
     margin-left:20%;
    }
    .Movement .m-text{
        margin-left: 1rem;
        width: 90%;
        text-align: start;
    }
    .Movement .m-text p{
        width: 100%;
    }
}
.Register{
    display: flex;
    flex-direction: column;
}
.Register .heading-btn{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color:#ED5009 ;
    gap: 0.5rem;
}

.Register .reg-area{

    display: flex;
    align-items: flex-end;
    justify-content: center;
 gap: 10%;
 margin-top: 2rem;
 
}
.Register .coach-rishi{
    background-color: white;
    height: 360px;
    width: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
    border-radius: 20px;
   
    border-bottom: 2px solid #ED5009;  
    text-align: justify;
}

.reg-area .coach-rishi h3{
  
    font-size: 1.1rem;
    font-weight: 600;
}



@media (max-width:1000px) {
    .Register .reg-img img{
        height: 300px;
    }
    .Register{
    gap: 1rem ;
   }
    .Register .reg-area{

      flex-direction: column;
        align-items: center;
        justify-content:center;
        gap: 1rem ;
        margin-right: 0.1rem;
    }
    .Register .coach-rishi{
           height: auto;
           width: 90%;
    }
    .reg-area .reg-text{
        margin-right: 0rem;
        width: 100%;
      
     
    }
  
}
.Main{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin-bottom: 3rem;
    width: 100%;
}

.temp-area-home{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  gap: 8%;
       width: 100%;
     
}
.card{

 cursor: pointer;
}
.card img{
    object-fit: contain;
    height: 16rem;
}
.card .knowmore{
    display: flex;
    flex-direction: column;
    width: 13rem;
}
.card .knowmore span{
    font-weight: 700;
    font-size: 0.9rem;
}
.knowmore button{
    display: flex;
    align-items: center;
    justify-content: flex-end;
  
}
.knowmore button svg{
    color:   #ED5009 ;
}
.blog-header-bg{
  background-image: url('../../../../../assets//blog-float.png');
}
.bg-blog-1{
  background-color: #FBF8F0;
}

.bg-blog{
  background-color: #FBF8F0;
  background-image: url('../../../../../assets/blog-float-2.png');
}
.article-title{
  color: #000000;
  font-size: 40px;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
}

/* .slick-dots li button {
  font-size: 20px;
  height: 20px;
  width: 20px;
 
} */
.slick-dots {
  bottom: -50px !important;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.slick-dots li {
  margin: 0 35px; 
}

/* .slick-dots li button:before {
  color: #fff;
  font-size: 12px;
} */

.border-bottom-prize{
    border-bottom: 8px solid #ED5009;
    width: 80px;
    margin: 0 auto;
}

.border-bottom-category{
    border-bottom: 8px solid #ED5009;
    margin: 0 auto;
    width: 90px;
}
.nav{
    position: absolute;
    right: 2rem;
    height: auto;
    width: 170px;
    background-color: white;
    z-index: 2;
}
.nav ul{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
   
}
.nav li{
    cursor: pointer;
    font-size: 0.9rem;
   padding-left: 1rem;
    height: 3rem;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    transition: 0.2s ease-in;
    border-bottom: 1px solid rgb(227, 227, 227);
}
.nav li:hover{
    background-color: rgb(244, 244, 244);
}
.nav li svg{
    color: rgb(106, 106, 106);
    font-size: 0.9rem;
    margin-left: 0.5rem;
}
.nav  .new-items{
    width: 100%;
  
}
.nav  .new-items li{

    width: 100%;
    padding-left: 20%;
   
}


.Athletes{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: #FBF8F0;
    font-family: 'League Spartan', sans-serif;
}
.Athletes h2{
    font-weight: bold;
    font-size: 1.5rem;
}
.Athletes p{
    font-size: 1.1rem;
}
.Athletes h3{
    font-weight: 700;
}
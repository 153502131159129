.collapse-content {
  grid-row-start: 2;
  overflow: hidden;
  max-height: 0px;
  padding-left: 1rem;
  padding-right: 0;
  cursor: unset;
  transition: padding 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
.speaker-name{
    font-size: 16px;
    text-align: center;
    color: #000000;
    font-family: "Oswald", sans-serif;
}
.myopia-title {
  color: #000000;
  font-size: 40px;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
}

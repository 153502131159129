.Compa{
 width: 100%;
}
.Companies{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  width: 90%;
  position: relative;
  margin-right: 5rem;


}
.Companies h3{
    font-weight: bold;
    font-size: 1.5rem;
    z-index: 2;
}
.Companies p{
    width: 60%;
    z-index: 2;
    text-align: end;
}
.comp-circle{
    position: absolute;
    height: 100px;
    right: -10%;
    bottom: 5%;
   
}
@media (max-width:500px) {
    .Companies{
   width: 100%;       
    }
    .Companies h3{
        font-size: 1.3rem;
        margin-right: 3rem;
    }
    .Companies p{
        width: 90%;
        margin-right: 3rem;
        margin-bottom: 0.5rem;
    }
}
.Introduction{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
  padding: 2rem 0;
    background-color: white;
    position: relative;
}
.Introduction h2{
    font-size: 2rem;
    font-weight: bold;
    
}
.Introduction .img-text-area{
    display: flex;
    align-items: baseline;
    justify-content: center;
    position: relative;
}
.Introduction .img-text-area img{
    margin-right: 7rem;
    height: 24rem;
}
.Introduction .img-text-area span{
    position: absolute;
    left: 23rem;
    bottom: 2rem;
   text-align: justify;
    width: 70%;
    font-size: 1.2rem;
    
}
@media (max-width:1000px) {
    .Introduction .img-text-area img{
        margin-right: 7rem;
        height: 18rem;
    }
    .Introduction .img-text-area span{
     font-size: 0.9rem;
     left: 15rem;
     bottom: 2rem;
      
    }
}
@media (max-width:500px) {
   
    .Introduction .img-text-area img{
        margin-right: 2rem;
        height: 15rem;
    }

    .Introduction .img-text-area span{
        position: absolute;
        left: 13rem;
        bottom: 0rem;
        top: 9.5rem;
       text-align: justify;
        width: 38%;
        font-size: 0.7rem;
    }
     .Introduction .for-work img{
        height: 13rem;
       margin-right: 0rem;
    }
    .Introduction .for-work span{
        width: 43%;
        left: 12rem;
        top: 8.5rem;
    }
}
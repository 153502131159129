.terms-bg{
    background-color: #FBF8F0;  
}

.terms-title{
    color: #000000;
    font-size: 40px;
    font-family: "Oswald", sans-serif;
    font-weight: 700;
}

.border-left{
    border-left: 8px solid #ED5009;
    height: 70px;
}

.collapse-title, :where(.collapse > input[type="checkbox"]) {
    width: 100%;
    /* padding: 1rem; */
    padding-right: 3rem;
    min-height: 3.75rem;
    transition: background-color 0.2s ease-in-out;
    padding: 0rem !important;
}
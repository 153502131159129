.work-with-bg{
    background-color: #FBF8F0;  
}

.work-with-title{
    color: #000000;
    font-size: 40px;
    font-family: "Oswald", sans-serif;
    font-weight: 700;
}

.fill-btn{
   padding: 10px; 
   background-color: #FD8C69;
   border-radius: 8px;
}